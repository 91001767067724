
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Filler);



function LineChart({height}) {
    const data = {
        labels: ["05/01", "05/02", "05/03", "05/04", "05/05", "05/06", "05/07", "05/08", "05/09", "05/10", "05/12", "05/13", "05/14", "05/15"],
        datasets: [
            {
                label: "Current",
                data: [63, 60, 61, 62, 65, 60, 62, 63, 60, 61, 64, 65, 60, 62],
                pointRadius: 0,
                pointHoverRadius: 2,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 120);
                    gradient.addColorStop(0, "rgba(69,93,255,0.5)");
                    gradient.addColorStop(1, "rgba(69,93,255,0)");
                    return gradient;
                }, //background gradient color
                borderColor: "#455DFF", //line color
                lineTension: 0.6,
                fill: true, // this line allow us to use background gradient

            },
            {
                label: "Optimal Configuration",
                data: [22, 45, 23, 41, 18, 11, 32, 31, 43, 54, 45, 49, 54, 36],
                pointRadius: 0,
                pointHoverRadius: 2,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 150);
                    gradient.addColorStop(0, "rgba(20,174,92,0.45)");
                    gradient.addColorStop(1, "rgba(20,174,92,0.0)");
                    return gradient;
                }, //background gradient color
                borderColor: "#14AE5C", //line color
                lineTension: 0.4,
                fill: true, // this line allow us to use background gradient

            }
        ]
    };


    return (
        <div style={{ height: height, width: '100%', paddingBottom: 15 }}>
            <Line id="myChart" data={data} options={{
                maintainAspectRatio: false,
                responsive: true,
                showTooltips: true,
                multiTooltipTemplate: "<%= value %>",
                scales: {
                    y: {
                        ticks: {
                            color: "white"},
                        grid: {
                            display:false
                        }
                    },
                    x: {
                        grid: {
                            display:false
                        },
                        ticks: {
                            color: "white"},
                    }
                },
                plugins: {
                    legend: {
                        //display: false,
                        position: "top",
                        labels:{usePointStyle: true, color: "#aaa", padding: 15, font:{size: 13, family: "Inter", weight: 500}}
                    }
                },
            }} /></div>
    );
}




export { LineChart };
