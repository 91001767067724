import {Card} from "antd";

export default function FullScreen({children}) {
    return (
        <div className="gradient-2 center-base">
            <div style={{textAlign: "center", marginTop: -100}}>
                <h1 style={{fontSize: 50}}>FirstMate</h1>
                <h2 style={{fontSize: 23}}>AI assisted cloud spend reduction & monitoring</h2>
                <Card style={{backgroundColor: "white", textAlign: "center", padding: "10px 50px"}}>
                    {children}
                </Card>
            </div>
        </div>
    );
}