import {Modal, Table, Tag} from 'antd';
import {LockOutlined, UnlockOutlined} from "@ant-design/icons";
import {useContext} from "react";
import {AccessRightsContext} from "../../../providers/AccessRightsProvider";

export default function AccessRightModal({open = true, onCancel, areAllRolesGranted}) {
    const {
        focussedAccessRight,
        focussedAccessRightParams
    } = useContext(AccessRightsContext)

    const roleColumns = [
        {
            title: 'Role Name',
            dataIndex: 'role',
        },
        {
            title: 'Scope',
            dataIndex: 'scope',
        },
        {
            title: 'Access',
            dataIndex: 'granted'
        },
    ];

    return (
        <Modal centered open={open} className={"with-background"}
               onCancel={onCancel} width={"1200px"} footer={[]}>

            <div className="modal-background"></div>
            <div className="modal-switch">
            </div>
            <div className="modal-icon">{areAllRolesGranted(focussedAccessRight) ? <UnlockOutlined/> :
                <LockOutlined/>}</div>
            <h2 style={{marginRight: 20, marginTop: -5}}>Control access rights</h2>
            <div style={{marginTop: 20}}><p>{focussedAccessRight?.description}</p></div>
            {
                focussedAccessRightParams?.type === "managedIdentity" && (
                    <div className="access-overiew">
                        <>
                            <p>This resource maps on the managed identity </p>
                            <p> name: <Tag>{focussedAccessRight?.resourceDetails?.name} </Tag></p>
                            <p> tenant Id: <Tag>{focussedAccessRight?.resourceDetails?.properties?.tenantId}  </Tag></p>
                            <p> client Id: <Tag>{focussedAccessRight?.resourceDetails?.properties?.clientId}  </Tag></p>
                            <p> resource group: <Tag>{focussedAccessRight?.resourceDetails?.resourceGroupName}  </Tag>
                            </p>
                        </>
                    </div>
                )
            }
            {
                focussedAccessRightParams?.type === "servicePrincipal" && (
                    <div className="access-overiew">
                        <>
                            <p>This resource maps on the service principal: </p>
                            <p> name: <Tag>{focussedAccessRight?.resourceDetails?.name} </Tag></p>
                            <p> tenant Id: <Tag>{focussedAccessRight?.resourceDetails?.properties?.tenantId} </Tag></p>
                            <p> client Id: <Tag>{focussedAccessRight?.resourceDetails?.properties?.clientId}</Tag></p>
                            <p> principal Id: <Tag>{focussedAccessRight?.resourceDetails?.properties?.principalId}</Tag>
                            </p>
                        </>
                    </div>
                )
            }

            <Table
                locale={{emptyText: 'No servicePrincipals found'}}
                columns={roleColumns}
                dataSource={focussedAccessRight?.roles}
            />
        </Modal>)

}



