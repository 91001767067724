import DashBoardHeader from "../dashboard/elements/Header";
import {Switch, Button, Alert, Col, Divider, Flex, Row, Table, Tag, Checkbox} from "antd";
import * as React from "react";
import {PageCard} from "../dashboard/elements/BaseCard";
import {TeamOutlined, RedoOutlined, RocketOutlined} from "@ant-design/icons";
import {useContext, useState} from "react";
import {UserContext} from "../../providers/UserProvider";
import Moment from 'moment';


const gutter = {xs: 8, sm: 16, md: 24, lg: 32}

export default function SubscriptionPlan() {
    const {organisation, backendService} = useContext(UserContext);
    const [users, setUsers] = useState(organisation.subscription.users);
    const [autoAssign, setAutoAssign] = useState(organisation.subscription.autoAssignSeats);
    const [error, setError] = useState(null);

    const openBillingDashboard = async () => {
        const res = await backendService.getSubscriptionDetails();
        window.location.href = res.url
    }

    const changeAutoAssign = async (value) => {
        setAutoAssign(!autoAssign);
        await backendService.changeSubscriptionSettings({autoAssignSeats: !autoAssign})
    }

    const assignSeats = async (record, value) => {
        setUsers(users.map((item) => {
            if (item.providerId === record.providerId) {
                return {...item, loading: true};
            } else {
                return item;
            }
        }));
        const assigned = value;
        try {
            await backendService.changeSeat(record)
        } catch (e) {
            setError("Could not claim seat. Check your subscription.");
            setUsers(users.map((item) => {
                if (item.providerId === record.providerId) {
                    return {...item, loading: false};
                } else {
                    return item;
                }
            }));
            return;
        }
        setUsers(users.map((item) => {
            if (item.providerId === record.providerId) {
                return {...item, assigned, loading: false};
            } else {
                return item;
            }
        }));

    }

    const columns = [
        {
            title: '',
            dataIndex: 'img',
            width: 100,
            render: (_, record) => <img alt="avatar" src="/square_icon.png"
                                        className="avatar"/>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 250,
        },
        {
            title: 'Last interactivity with FirstMate',
            dataIndex: 'lastActive',
            sorter: (a, b) => a.lastActive - b.lastActive,
            defaultSortOrder: 'descend',
            render: (_, record) =>  Moment.utc(record.modificationTime).format('D MMM YY')
        },
        {
            title: 'Status',
            dataIndex: 'assigned',
            align: 'center',
            width: 150,
            render: (_, record) => <Switch loading={record.loading} value={record.assigned}
                                           onChange={(value) => assignSeats(record, value)}/>

        },
    ];
    let daysRemaining = 0;
    let lastTrialDay = ""
    const free_trial = organisation?.subscription.details.status === "trialing";
    const paused = organisation?.subscription.details.status === "paused";
    if (free_trial) {

        const trialEndDate = new Date(organisation?.subscription.details.trial_end * 1000);

        // Get the current date
        const currentDate = new Date();

        // Calculate the difference in time (milliseconds)
        const timeDiff = trialEndDate - currentDate;

        // Calculate days left by converting milliseconds to days
        daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    }

    if (paused) {
        Moment.locale('en');
        lastTrialDay = Moment(organisation?.subscription.details.trial_end * 1000).format('D MMM YY')
    }
    console.log(organisation?.subscription);

    return <div>
        <DashBoardHeader title={"Subscription"} hideAlerts={true}/>
        <Row gutter={gutter}>
            <Col span={12}>
                <PageCard>
                    <Flex justify={"right"} style={{position: "absolute", right: 10}}>
                        <Button type={"primary"} icon={<RocketOutlined/>} onClick={openBillingDashboard}>
                            {paused ? "Resume subscription" : "Manage Subscription"}</Button></Flex>
                    <img alt="logo" src="/square_icon.png" className={"sub-pic"}/>
                    <h2>{organisation.name} </h2><p
                    className={"accent-field" + (paused ? " accent-paused" : "")}>{organisation?.subscription.details?.plan.product.name} {paused && "PAUSED"}</p>
                    <Divider/>
                    {free_trial? <Checkbox checked={autoAssign}>Automatically link seats to new developers (always on in free tier)</Checkbox>:
                         <Checkbox onChange={changeAutoAssign} checked={autoAssign}>Automatically link seats to new developers</Checkbox>
                    }

                    {free_trial ?
                        <div><TeamOutlined style={{marginRight: 5}}/> { users.filter(u => u.assigned).length}/20 seats in use. (upgrade license for more seats)</div> :
                        paused ? <div><TeamOutlined style={{marginRight: 5}}/> Please resume subscription to add seats
                            </div> :
                            <div><TeamOutlined
                                style={{marginRight: 5}}/> { users.filter(u => u.assigned).length}/{organisation?.subscription.details.quantity} seats
                                in use</div>
                    }
                    {free_trial ?
                        <div><RedoOutlined style={{marginRight: 5}}/>Ending free trial in <Tag
                            color={"green"}>{daysRemaining} days</Tag>
                        </div> :
                        paused ?
                            <div><RedoOutlined style={{marginRight: 5}}/>Free trial ended on <Tag>{lastTrialDay}</Tag>
                            </div> :
                            <div><RedoOutlined style={{marginRight: 5}}/>Renewing subscription on <Tag>{Moment(organisation?.subscription.details.current_period_end * 1000).format('d MMMM YY')}</Tag>
                            </div>
                    }


                </PageCard>

            </Col>

        </Row>

        <PageCard>
            {error && <Alert style={{marginBottom: 15}} message={error} type="error" showIcon closable/>}
            <h2>Seats</h2>
            <p className={"secondary"}>Select which users can interact with FirstMate</p>

            <Table pagination={{pageSize: 10}} columns={columns}
                   dataSource={users}></Table>
        </PageCard>
    </div>
}