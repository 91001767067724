import {createContext, useContext, useEffect, useState} from 'react';
import {UserContext} from "./UserProvider";
import moment from "moment";

export const MetricContext = createContext([]);

export default function MetricProvider({children}) {
    const {backendService} = useContext(UserContext);
    const [metrics, setMetrics] = useState(null)
    const [metricsReady, setMetricsReady] = useState(false)
    const [projects, setProjects] = useState(null)
    const [zoom, setZoom] = useState('hour')


    const getProjectMetrics = async (params) => {
        try {
            const projectData = await backendService.getProjectMetrics(params);
            setProjects(projectData);
        } catch (e) {
        }
    }
    const getGeneralMetrics = async (params) => {
        try {
            const generalData = await backendService.getGeneralMetrics(params);
            setMetrics(generalData);
        } catch (e) {
        }
        setMetricsReady(true);
    }

    const changeZoom = (event) => {
        setZoom(event.target.value);
        //setProjects(null);

    }


    useEffect(() => {
        if (zoom === "hour")
            getProjectMetrics({step: "1h", start: moment().utc(true).subtract(24, "hour").toISOString()});
        if (zoom === "day")
            getProjectMetrics({step: "1d", start: moment().utc(true).subtract(31, "day").toISOString()});
        // eslint-disable-next-line
    }, [zoom])

    useEffect(() => {
        getGeneralMetrics()
        // eslint-disable-next-line
    }, [])

    return (
        <MetricContext.Provider value={{metrics, projects, zoom, changeZoom,metricsReady}}>
            {children}
        </MetricContext.Provider>
    )
}