import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {UserContext} from "../../providers/UserProvider";
import Loader from "../dashboard/elements/Loader";
import {Col, Row} from "antd";
import AzureDevSetup from "./AzureSetupSteps"
import BlockingModal from "../dashboard/elements/PermissionModal";

function LoadingView() {
    return <Row
        type="flex"
        justify="center"
        align="middle"
        style={{minHeight: '100vh'}}
    >
        <Col>
            <Loader size={"80"}/>
            <p style={{marginTop: 20, fontSize: 15}}>Connecting to your source control. Do not refresh the page.</p>
        </Col>
    </Row>
}

export default function ProviderRedirect() {
    const navigate = useNavigate();
    const {backendService, iam, organisation, askReload} = useContext(UserContext)

    const {provider} = useParams();
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams()

    const checkAccessRights = async values => {
        backendService.addAccessRights("provider", {
            id: `${provider}-app`,
            resourceName: `${provider}-app`,
            provider: provider,
            resourceDetails: {
                ...paramsToObject(searchParams),
                ...values,
                manualPermissionCreation: values.manualPermissionCreation
            }
        }).then(() => {
            askReload(true);
            navigate("/dashboard/repos");
            setLoading(true)
        }).catch((e)=> console.log(e))

    }


    const createAccessRight = useCallback(async (values) => {
        setLoading(true)
        await backendService.addAccessRights("provider", {
            id: `${provider}-app`,
            resourceName: `${provider}-app`,
            provider: provider,
            resourceDetails: {
                ...paramsToObject(searchParams),
                ...values,
            }
        })
        askReload(true);
        navigate("/dashboard/repos");
        setLoading(true)

    }, [searchParams, provider, backendService, navigate, askReload])

    useEffect(() => {
        if (!iam || loading)
            return
        if (provider !== "azuredev") {
            createAccessRight(undefined).then()
        }
    }, [provider, iam, loading, createAccessRight])
    const params = paramsToObject(searchParams)
    if (params.error) {
        return <BlockingModal title={"Error: " + params.error} content={params.error_description}></BlockingModal>
    }

    if (provider === "azuredev") {
        return loading ?
            <LoadingView/> : <AzureDevSetup apiKey={organisation?.apiKey} finish={checkAccessRights}/>
    }
    return <LoadingView/>
}

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}